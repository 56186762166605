import { ExamMetadata } from "@/graphql/customSchema";
import { AP_EXAM_NAMES, AP_EXAM_SEARCH_TERMS } from "@/hooks/exams/constants";
import { fromEntries } from "@/utils/genericUtils";
import { Exam } from "@knowt/syncing/graphql/schema";

export const EXAMS_BUCKET = "https://knowt-exam-images.s3.amazonaws.com/";

export const getFeaturedCategories = examsByCategory => {
    return Object.values(examsByCategory).map(backendToFrontendFeaturedCategory);
};

export const groupExamsByCategory = (exams: Exam[], categories) => {
    const categoriesWithExams = (exams || []).map(({ category, type }) => ({
        ...categories[category],
        type,
        category,
        exams: exams.filter(exam => exam.category === category),
    }));

    return fromEntries(categoriesWithExams.map(item => [item.category || "", item]));
};

export const groupExamsByType = (exams: (ExamMetadata | Exam)[]) => {
    const typesWithExams = (exams || []).map(({ type }) => ({
        type,
        exams: exams.filter(exam => exam.type === type),
    }));

    return fromEntries(typesWithExams.map(item => [item.type, item]));
};

export type FrontendFeaturedCategory = {
    category: string;
    image: string;
    subjects: Array<{ subject: string; route: string; image: string }>;
    route: string;
};

// TODO: We should fix this code (almost shared between subjects and exams),
//  but it gets really confusing when we are using subjects as the key to map exams into
const backendToFrontendFeaturedCategory = ({
    type,
    category,
    exams,
}: {
    type: ExamMetadata["type"];
    category: ExamMetadata["category"];
    exams: (ExamMetadata | Exam)[];
}): FrontendFeaturedCategory => ({
    // we would use category if we're on the /exam/{type} page, and type on the general /explore or /exam page
    category: category || type,
    image: getImageLink(category || type),
    // TODO: rename to items
    subjects: exams.map(({ name }) => ({
        subject: name,
        route: `/exams/${type}/${examNameToUrlComponent(name)}`,
        image: getImageLink(name),
    })),
    route: `/exams/${type}`,
});

// in the url, we do some replaces to make the url in a readable format
// ": " -> "_"
// " - " -> "="
// "-" -> "."
// " " -> "-"
// "A-Level Biology: Edexcel" -> "A.Level-Biology_Edexcel"

export const examNameToUrlComponent = (name: string) =>
    encodeURIComponent(
        (name || "").replace(/: /g, "_").replace(/ - /g, "=").replace(/-/g, ".").replace(/ /g, "-").replace(/’/g, "'")
    );

export const examUrlComponentToName = (urlComponent: string) =>
    decodeURIComponent(urlComponent)
        .replace("'", "’")
        .replace(/_/g, ": ")
        .replace(/-/g, " ")
        .replace(/\./g, "-")
        .replace(/=/g, " - ");

export const examTypeNameToUrlComponent = (_name: string) => {
    const name = _name.includes("-") ? _name : _name.toUpperCase();
    return (name || "").replace(/: /g, "_").replace(/ - /g, "=").replace(/-/g, ".").replace(/ /g, "-");
};

export const examTypeUrlComponentToName = (urlComponent: string) => {
    if (urlComponent === "A-Level") return "A-Level";
    const _type = examUrlComponentToName(urlComponent);
    return _type;
};

export const getExamNameAndType = (param: string) =>
    param
        ? {
              type: param.split("-").pop() as "notes" | "flashcards" | string,
              param: examUrlComponentToName(param.split("-").slice(0, -1).join("-")),
          }
        : { type: undefined, param: undefined };

export const getFileName = (name: string) => {
    return (
        name
            .toLowerCase()
            .replace(/: /g, "_")
            .replace(/ - /g, "_")
            .replace(/ /g, "_")
            .replace(/-/g, "_")
            .replace(/\(/g, "")
            .replace(/\)/g, "")
            .replace(/,/g, "")
            .replace(/&/g, "and") + ".svg"
    );
};

export const getImageLink = (name?: string | null) => {
    if (!name) return undefined;
    return EXAMS_BUCKET + getFileName(name);
};

export const getExamSuggestions = (query: string) => {
    if (!query || query.length < 2) return [];
    const lowerCaseQuery = query.toLowerCase();

    const suggestions = AP_EXAM_NAMES.map(exam => ({
        text: exam,
        searchTerms: (AP_EXAM_SEARCH_TERMS[exam] ?? []).map(term => term.toLowerCase()),
    })).filter(
        ({ text, searchTerms }) =>
            text.toLowerCase().includes(lowerCaseQuery) || searchTerms.some(term => term.includes(lowerCaseQuery))
    );

    return suggestions.map(({ text }) => ({ text }));
};
